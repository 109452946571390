import {
  Box,
  Heading,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import React, { FC } from "react";
import { itemSize } from "../globals";
import { Component, Position } from "../types/canva";

type Props = {
  components: Component[];
  position: Position;
};

const PreviewComponent: FC<Props> = ({ components, position }) => {
  const c = components.find(
    (c) =>
      c.x <= position.x &&
      position.x <= c.x + itemSize &&
      c.y <= position.y &&
      position.y <= c.y + itemSize
  );

  const bgColor = useColorModeValue("gray.100", "blue.900");
  const itemColor = useColorModeValue("black", "white");

  if (!c) {
    return null;
  }

  return (
    <Box
      pos="absolute"
      zIndex={2}
      right={5}
      bottom={5}
      bgColor={bgColor}
      width={400}
      borderRadius={10}
      padding={10}
    >
      <VStack>
        <Heading size="md" color={itemColor}>
          {c.name}
        </Heading>
        <Text color={itemColor} maxWidth={350}>
          {c.description}
        </Text>
      </VStack>
    </Box>
  );
};

export default PreviewComponent;
