export type Position = {
  x: number;
  y: number;
};

export type Component = {
  name: string; // used as id
  description: string;
  preview: boolean;
  icon: string;
} & Position;

export type LinkType = "asynchronous" | "synchronous";

export const LinkTypeArray = ["asynchronous", "synchronous"];

export type Link = {
  source: string;
  target: string;
  preview: boolean;
  type: LinkType;
};

export type Step = {
  source: string;
  target: string;
  description: string;
};

export type Scenario = {
  name: string;
  steps: Step[][];
};

export type CanvaContent = {
  components: Component[];
  links: Link[];
  scenarios: Scenario[];
};

export type Icon =
  | "database" // srip
  | "nestjs"
  | "go"
  | "react" // Pixel perfect
  | "typescript" // Freepik
  | "nextjs"
  | "kafka"
  | "redis"
  | "kubernetes";

export const IconArray = [
  "database",
  "nestjs",
  "go",
  "react",
  "typescript",
  "nextjs",
  "kafka",
  "redis",
  "kubernetes",
];
