import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { AddIcon, EditIcon } from "@chakra-ui/icons";
import { VStack } from "@chakra-ui/layout";
import {
  Box,
  Button,
  Heading,
  HStack,
  Input,
  Select,
  Text,
  Textarea,
} from "@chakra-ui/react";
import React, { FC, useState } from "react";
import { Link, Scenario, Step } from "../types/canva";

type Props = {
  goToMenu: () => void;
  links: Link[];
  setError: (s: string) => void;
  addScenario: (s: Scenario) => void;
  itemColor: string;
  bgColor: string;
};

type CreateStepProps = {
  addStep: (s: Step[]) => void;
  currentStep: Step[] | null;
  bgColor: string;
  links: Link[];
};

const CreateStep: FC<CreateStepProps> = ({
  addStep,
  currentStep,
  bgColor,
  links,
}) => {
  const [steps, setSteps] = useState<Step[]>(currentStep ? currentStep : []);
  return (
    <Box
      pos="absolute"
      zIndex={2}
      left={350}
      bottom={0}
      w={500}
      h={300}
      borderRadius={10}
      padding={10}
      overflowY="scroll"
      bgColor={bgColor}
    >
      <VStack>
        {steps.map((step, i) => (
          <FormControl key={i} id={`step ${i}`}>
            <FormLabel>Step part {i + 1}</FormLabel>
            <Select
              placeholder="chose link"
              value={`${step.source} -> ${step.target}`}
              onChange={(e) =>
                setSteps(
                  steps.map((s, y) => {
                    if (i === y) {
                      return {
                        ...s,
                        source: e.target.value.split(" -> ")[0],
                        target: e.target.value.split(" -> ")[1],
                      };
                    }
                    return s;
                  })
                )
              }
            >
              {links
                .filter(
                  (l) =>
                    !steps.find(
                      (s) => s.source === l.source && s.target === l.target
                    )
                )
                .map((l, x) => (
                  <option key={x}>{`${l.source} -> ${l.target}`}</option>
                ))}
            </Select>
            <Textarea
              placeholder="data"
              value={step.description}
              onChange={(e) =>
                setSteps(
                  steps.map((s, y) => {
                    if (i === y) {
                      return { ...s, description: e.target.value };
                    }
                    return s;
                  })
                )
              }
            />
          </FormControl>
        ))}
        <AddIcon
          onClick={() => {
            setSteps(
              steps.concat([{ source: "", target: "", description: "" }])
            );
          }}
        />
        <Button
          onClick={() => {
            if (
              steps.find(
                (step) => !step.source || !step.target || !step.description
              )
            ) {
              return;
            }
            addStep(steps);
            setSteps([]);
          }}
        >
          Validate Step
        </Button>
      </VStack>
    </Box>
  );
};

const CreateScenario: FC<Props> = ({
  addScenario,
  itemColor,
  bgColor,
  setError,
  goToMenu,
  links,
}) => {
  const [name, setName] = useState<string>("");
  const [currentEdit, setCurrentEdit] = useState<number | null>(null);
  const [steps, setSteps] = useState<Step[][] | null>(null);

  if (links.length <= 1) {
    return (
      <Heading size="medium">
        You must create at least 2 links before creating a scenario
      </Heading>
    );
  }
  return (
    <VStack spacing="10px">
      <CreateStep
        links={links}
        addStep={(s: Step[]) => {
          if (steps && currentEdit) {
            setSteps(
              steps.map((value, i) => {
                if (i === currentEdit) {
                  return s;
                } else {
                  return value;
                }
              })
            );
            return;
          }
          setSteps(steps ? steps.concat([s]) : [s]);
        }}
        bgColor={bgColor}
        currentStep={steps && currentEdit ? steps[currentEdit] : null}
      />
      <FormControl id="name" color={itemColor}>
        <FormLabel>Scenario Name</FormLabel>
        <Input
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </FormControl>
      {steps?.map((s, i) => (
        <HStack key={i}>
          <Text>
            {i} - {s.length} steps
          </Text>
          <EditIcon
            onClick={() => {
              setCurrentEdit(i);
            }}
          />
        </HStack>
      ))}
      <Button
        onClick={() => {
          if (!name.length || !steps?.length) {
            setError("Please enter a name and at least one step");
            return;
          }
          addScenario({
            name,
            steps,
          });
          goToMenu();
        }}
      >
        Create Scenario
      </Button>
    </VStack>
  );
};

export default CreateScenario;
