import { Icon, IconButton, Input } from "@chakra-ui/react";
import React, { FC, Fragment, useRef, useState } from "react";
import { BiUpload } from "react-icons/bi";

type Props = {
  onUpload: (content: string) => void;
};

const Upload: FC<Props> = ({ onUpload }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const uploadRef = useRef(null);

  if (selectedFile) {
    selectedFile
      .text()
      .then((text) => onUpload(text))
      .catch(console.error);
    setSelectedFile(null);
  }

  return (
    <Fragment>
      <Input
        type="file"
        name="file"
        onChange={(e) => {
          setSelectedFile(e.target.files ? (e.target.files[0] as File) : null);
        }}
        icon={<Icon as={BiUpload} />}
        style={{ display: "none" }}
        ref={uploadRef}
      ></Input>

      <IconButton
        position="absolute"
        zIndex={2}
        top={5}
        left={135}
        icon={<Icon as={BiUpload} />}
        aria-label={"Download your doc"}
        onClick={() => uploadRef.current?.click()}
      />
    </Fragment>
  );
};

export default Upload;
