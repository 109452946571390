import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Select,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import React, { FC, useEffect, useState } from "react";
import { Component, IconArray } from "../types/canva";

type Props = {
  goToMenu: () => void;
  setError: (s: string) => void;
  addComponent: (c: Component) => void;
  itemColor: string;
};

const CreateComponent: FC<Props> = ({
  goToMenu,
  setError,
  addComponent,
  itemColor,
}) => {
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [icon, setIcon] = useState<string>("");
  const [x, setX] = useState<number>(0);
  const [y, setY] = useState<number>(0);

  useEffect(() => {
    if (name.length && description.length && icon.length) {
      addComponent({
        name,
        description,
        preview: true,
        icon,
        x,
        y,
      });
    }
    // eslint-disable-next-line
  }, [x, y, name, description, icon]);

  return (
    <VStack spacing="10px">
      <FormControl id="name" color={itemColor}>
        <FormLabel>Name</FormLabel>
        <Input
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </FormControl>
      <FormControl id="description" color={itemColor}>
        <FormLabel>Description</FormLabel>
        <Textarea
          placeholder="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </FormControl>
      <FormControl>
        <FormLabel>Icon</FormLabel>
        <Select
          placeholder="Select Icon"
          value={icon}
          onChange={(e) => setIcon(e.target.value)}
        >
          {IconArray.map((value) => (
            <option>{value}</option>
          ))}
        </Select>
      </FormControl>
      <FormControl color={itemColor}>
        <FormLabel>Position</FormLabel>
        <HStack>
          <Text>x:</Text>
          <Input
            type="number"
            placeholder="x"
            value={x.toString()}
            onChange={(e) => setX(parseInt(e.target.value))}
          />
          <Text>y:</Text>
          <Input
            type="number"
            placeholder="y"
            value={y.toString()}
            onChange={(e) => setY(parseInt(e.target.value))}
          />
        </HStack>
      </FormControl>
      <Button
        onClick={() => {
          if (!name.length || !description.length || !icon.length) {
            setError("Empty field(s)");
            return;
          }
          addComponent({
            name,
            description,
            icon,
            preview: false,
            x,
            y,
          });
          goToMenu();
        }}
      >
        Create component
      </Button>
    </VStack>
  );
};

export default CreateComponent;
