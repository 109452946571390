import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { Box, HStack, Select, useColorModeValue } from "@chakra-ui/react";
import { Graph as VisxGraph } from "@visx/network";
import React, { FC, Fragment, useState } from "react";
import { itemSize } from "../globals";
import { CanvaContent, Component, Link } from "../types/canva";

type Props = {
  content: CanvaContent | undefined;
};

type LinkProps = {
  content: CanvaContent;
  target: string;
  source: string;
  type: string;
  itemColor: string;
  highlight?: boolean;
  description?: string;
};

type CustomLinkType = Link & {
  itemColor: string;
  highlight?: boolean;
  description?: string;
};

const CustomLink: FC<LinkProps> = ({
  content,
  target,
  source,
  type,
  itemColor,
  highlight,
  description,
}) => {
  const mitemSize = itemSize / 2;
  const refTarget = content.components.find((c) => c.name === target);
  const refSource = content.components.find((c) => c.name === source);

  if (!refTarget || !refSource) {
    return <></>;
  }

  const x1 =
    refSource.x +
    (Math.abs(refTarget.x - refSource.x) <= itemSize * 3
      ? mitemSize
      : refTarget.x - refSource.x > 0
      ? itemSize
      : 0);

  const y1 =
    refSource.y +
    (Math.abs(refTarget.y - refSource.y) <= itemSize * 3
      ? mitemSize
      : refTarget.y - refSource.y > 0
      ? itemSize
      : 0);

  const x2 =
    refTarget.x +
    (Math.abs(refSource.x - refTarget.x) <= itemSize * 3
      ? mitemSize
      : refSource.x - refTarget.x > 0
      ? itemSize
      : 0);

  const y2 =
    refTarget.y +
    (Math.abs(refSource.y - refTarget.y) <= itemSize * 3
      ? mitemSize
      : refSource.y - refTarget.y > 0
      ? itemSize
      : 0);

  return (
    <Fragment>
      <line
        x1={x1}
        y1={y1}
        x2={x2}
        y2={y2}
        strokeWidth={highlight ? 4 : 2}
        stroke="#999"
        strokeOpacity={highlight ? 1 : 0.6}
        strokeDasharray={type === "asynchronous" ? "8,4" : undefined}
      />
      {description && (
        <text
          x={(x1 + x2) / 2 - description?.length * 3}
          y={(y1 + y2) / 2 - 20}
          width={100}
          fill={itemColor}
        >
          {description}
        </text>
      )}
    </Fragment>
  );
};

const Graph: FC<Props> = ({ content }) => {
  const [scenario, setScenario] = useState<string | null>(null);
  const [scenarioIndex, setScenarioIndex] = useState<number>(0);

  const currentScenario = content
    ? content.scenarios.find((s) => s.name === scenario) || null
    : null;

  const graph = {
    nodes: content ? content.components : [],
    links: content
      ? content.links.map((l) => {
          if (currentScenario) {
            const steps = currentScenario.steps[scenarioIndex];
            for (const s of steps) {
              if (s.source === l.source && s.target === l.target) {
                return {
                  ...l,
                  highlight: true,
                  description: s.description,
                };
              }
            }
          }
          return l;
        })
      : [],
  };

  const bgColor = useColorModeValue("white", "#272b4d");
  const itemColor = useColorModeValue("black", "white");

  return (
    <Box width="100%" height="100%">
      {content?.scenarios.length && (
        <Select
          w={250}
          pos="absolute"
          zIndex={2}
          right={5}
          top={5}
          placeholder="chose scenario"
          value={scenario ? scenario : ""}
          onChange={(e) => {
            setScenario(e.target.value);
            setScenarioIndex(0);
          }}
        >
          {content?.scenarios.map((s, i) => (
            <option key={i}>{s.name}</option>
          ))}
        </Select>
      )}
      {scenario && (
        <HStack pos="absolute" zIndex={2} right={120} top={75} spacing={25}>
          <ArrowBackIcon
            onClick={() =>
              scenarioIndex > 0 && setScenarioIndex(scenarioIndex - 1)
            }
            color={scenarioIndex <= 0 ? "gray" : itemColor}
            _hover={scenarioIndex > 0 ? { cursor: "pointer" } : {}}
          />
          <ArrowForwardIcon
            onClick={() =>
              currentScenario &&
              currentScenario.steps.length - 1 > scenarioIndex &&
              setScenarioIndex(scenarioIndex + 1)
            }
            color={
              currentScenario &&
              currentScenario.steps.length - 1 <= scenarioIndex
                ? "gray"
                : itemColor
            }
            _hover={
              currentScenario &&
              currentScenario.steps.length - 1 > scenarioIndex
                ? { cursor: "pointer" }
                : {}
            }
          />
        </HStack>
      )}
      <svg width="100%" height="100%">
        <rect width="100%" height="100%" rx={14} fill={bgColor} />
        {content && (
          <VisxGraph<CustomLinkType, Component>
            graph={graph}
            nodeComponent={({ node }) => (
              <image
                width={itemSize}
                height={itemSize}
                href={`/${node.icon}.png`}
              />
            )}
            linkComponent={({
              link: { source, target, type, highlight, description },
            }) => (
              <CustomLink
                content={content}
                target={target}
                source={source}
                type={type}
                highlight={highlight}
                description={description}
                itemColor={itemColor}
              />
            )}
          />
        )}
      </svg>
    </Box>
  );
};

export default Graph;
