import { DownloadIcon } from "@chakra-ui/icons";
import {
  Box,
  CloseButton,
  Flex,
  Heading,
  IconButton,
  Input,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { FC, Fragment, useState } from "react";
import { storageName } from "../globals";

const Download: FC = () => {
  const now = new Date();
  const defaultName = `${now.getFullYear()}-${now.getMonth()}-${now.getDay()}.dataflowdoc`;
  const [download, setDownload] = useState<boolean>(false);
  const [name, setName] = useState<string>(defaultName);

  const bgColor = useColorModeValue("gray.100", "blue.900");

  return (
    <Fragment>
      <IconButton
        position="absolute"
        zIndex={2}
        top={5}
        left={75}
        icon={<DownloadIcon />}
        aria-label={"Download your doc"}
        onClick={() => setDownload(true)}
      />
      {download && (
        <Box
          pos="absolute"
          zIndex={2}
          top="20vh"
          left="33vw"
          bgColor={bgColor}
          width="33vw"
          borderRadius={10}
          padding={5}
        >
          <CloseButton
            onClick={() => {
              setDownload(false);
              setName(defaultName);
            }}
            size="sm"
          />
          <Flex direction="column" height={300} justifyContent="space-evenly">
            <Heading size="lg" textAlign="center">
              Save you dataflowdoc
            </Heading>
            <Input
              bgColor="white"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <a
              href={`data:text/json;charset=utf-8,${encodeURIComponent(
                localStorage.getItem(storageName) ?? ""
              )}`}
              download={name.length > 0 ? name : defaultName}
              onClick={() => setDownload(false)}
            >
              Download
            </a>
          </Flex>
        </Box>
      )}
    </Fragment>
  );
};

export default Download;
