import { ArrowBackIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Heading,
  Icon,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import React, { FC, useState } from "react";
import { Component, Link, Scenario } from "../types/canva";
import CreateComponent from "./CreateComponent";
import CreateLink from "./CreateLink";
import CreateScenario from "./CreateScenario";

type Options = "menu" | "component" | "link" | "scenario";

type Props = {
  addComponent: (c: Component) => void;
  addLink: (l: Link) => void;
  addScenario: (s: Scenario) => void;
  componentsList: Component[];
  linksList: Link[];
};

const Toolbox: FC<Props> = ({
  addComponent,
  addLink,
  addScenario,
  componentsList,
  linksList,
}) => {
  const [option, setOption] = useState<Options>("menu");
  const [error, setError] = useState<string | null>(null);

  const bgColor = useColorModeValue("gray.100", "blue.900");
  const itemColor = useColorModeValue("black", "white");
  const layoutColor = useColorModeValue("gray.300", "blue.500");

  return (
    <Box
      pos="absolute"
      zIndex={2}
      left={5}
      bottom={5}
      bgColor={bgColor}
      w={300}
      borderRadius={10}
      padding={10}
    >
      {error && (
        <Alert status="error">
          <AlertIcon /> <AlertTitle mr={2}>{error}</AlertTitle>{" "}
        </Alert>
      )}
      {option !== "menu" && (
        <Icon
          w={5}
          h={5}
          _hover={{ cursor: "pointer" }}
          onClick={() => {
            setOption("menu");
            setError(null);
          }}
          color={itemColor}
          as={ArrowBackIcon}
        />
      )}
      {option === "menu" && (
        <VStack spacing="15px">
          <Heading size="lg" textAlign="center" color={itemColor}>
            Toolbox
          </Heading>
          <Button
            bgColor={layoutColor}
            onClick={() => setOption("component")}
            w="100%"
          >
            Create Component
          </Button>
          <Button
            bgColor={layoutColor}
            onClick={() => setOption("link")}
            w="100%"
          >
            Create Link
          </Button>
          <Button
            bgColor={layoutColor}
            onClick={() => setOption("scenario")}
            w="100%"
          >
            Create Scenario
          </Button>
        </VStack>
      )}
      {option === "component" && (
        <CreateComponent
          goToMenu={() => setOption("menu")}
          setError={(e) => setError(e)}
          addComponent={addComponent}
          itemColor={itemColor}
        />
      )}
      {option === "link" && (
        <CreateLink
          goToMenu={() => setOption("menu")}
          components={componentsList}
          setError={(e) => setError(e)}
          addLink={addLink}
          itemColor={itemColor}
        />
      )}
      {option === "scenario" && (
        <CreateScenario
          goToMenu={() => setOption("menu")}
          links={linksList}
          setError={(e) => setError(e)}
          addScenario={addScenario}
          itemColor={itemColor}
          bgColor={bgColor}
        />
      )}
    </Box>
  );
};

export default Toolbox;
