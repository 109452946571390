import { Box, useColorModeValue } from "@chakra-ui/react";
import React, { FC, useState } from "react";
import { ColorModeSwitcher } from "../ColorModeSwitcher";
import Download from "../components/Download";
import Graph from "../components/Graph";
import PreviewComponent from "../components/PreviewComponent";
import Toolbox from "../components/Toolbox";
import Upload from "../components/Upload";
import { storageName } from "../globals";
import {
  CanvaContent,
  Component,
  Link,
  Position,
  Scenario,
} from "../types/canva";

const Canva: FC = () => {
  const [canvaContent, setCanvaContent] = useState<CanvaContent | undefined>(
    undefined
  );
  const [click, setClick] = useState<Position | undefined>(undefined);

  const addComponent = (c: Component) => {
    if (!canvaContent) {
      setCanvaContent({
        components: [c],
        links: [],
        scenarios: [],
      });
      return;
    }
    const tab = canvaContent.components.filter((e) => e.name !== c.name) || [];
    setCanvaContent({
      ...canvaContent,
      components: tab.concat(c),
    });
  };

  const addLink = (l: Link) => {
    if (!canvaContent) {
      setCanvaContent({
        components: [],
        links: [l],
        scenarios: [],
      });
      return;
    }
    const tab =
      canvaContent.links.filter(
        (e) => e.source !== l.source || e.target !== l.target
      ) || [];
    setCanvaContent({
      ...canvaContent,
      links: tab.concat(l),
    });
  };

  const addScenario = (s: Scenario) => {
    if (!canvaContent) {
      setCanvaContent({
        components: [],
        links: [],
        scenarios: [s],
      });
      return;
    }
    const tab = canvaContent.scenarios.filter((e) => e.name !== s.name) || [];
    setCanvaContent({
      ...canvaContent,
      scenarios: tab.concat(s),
    });
  };

  const onUpload = (content: string) => {
    const value: CanvaContent | undefined = JSON.parse(content);
    if (!value) {
      return;
    }
    setCanvaContent(value);
  };

  if (canvaContent) {
    localStorage.setItem(storageName, JSON.stringify(canvaContent));
  } else {
    const value = localStorage.getItem(storageName);
    if (value) {
      setCanvaContent(JSON.parse(value));
    }
  }
  const bgColor = useColorModeValue("white", "#272b4d");

  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      right={0}
      bottom={0}
      overflow="auto"
      bgColor={bgColor}
      onMouseDown={(e) => setClick({ x: e.clientX, y: e.clientY })}
    >
      <ColorModeSwitcher position="absolute" zIndex={2} top={5} left={2} />
      <Download value={canvaContent} />
      <Upload onUpload={onUpload} />
      <Graph content={canvaContent}></Graph>
      <Toolbox
        addComponent={addComponent}
        addLink={addLink}
        addScenario={addScenario}
        componentsList={canvaContent ? canvaContent.components : []}
        linksList={canvaContent ? canvaContent.links : []}
      />
      {click && canvaContent && (
        <PreviewComponent
          components={canvaContent.components}
          position={click}
        />
      )}
    </Box>
  );
};

export default Canva;
