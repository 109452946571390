import {
  Button,
  FormControl,
  FormLabel,
  Heading,
  Select,
  VStack,
} from "@chakra-ui/react";
import React, { FC, useEffect, useState } from "react";
import { Component, Link, LinkType, LinkTypeArray } from "../types/canva";

type Props = {
  goToMenu: () => void;
  components: Component[];
  setError: (s: string) => void;
  addLink: (l: Link) => void;
  itemColor: string;
};

const CreateLink: FC<Props> = ({
  setError,
  addLink,
  itemColor,
  components,
  goToMenu,
}) => {
  const [first, setFirst] = useState<string>("");
  const [second, setSecond] = useState<string>("");
  const [type, setType] = useState<LinkType>("synchronous");

  useEffect(() => {
    if (first.length && second.length) {
      const source = components.find((c) => c.name === first)?.name;
      const target = components.find((c) => c.name === second)?.name;
      if (!source || !target) {
        return;
      }
      addLink({
        source,
        target,
        type,
        preview: true,
      });
    }
    // eslint-disable-next-line
  }, [first, second, type, components]);

  if (components.length <= 1) {
    return (
      <Heading size="medium">
        You must create at least 2 components before links
      </Heading>
    );
  }

  return (
    <VStack spacing="10px">
      <FormControl id="first" color={itemColor}>
        <FormLabel>Between</FormLabel>
        <Select
          placeholder="chose component"
          value={first}
          onChange={(e) => setFirst(e.target.value)}
        >
          {components
            .filter((c) => c.name !== second)
            .map((c) => (
              <option>{c.name}</option>
            ))}
        </Select>
      </FormControl>
      <FormControl id="second" color={itemColor}>
        <FormLabel>And</FormLabel>
        <Select
          placeholder="chose component"
          value={second}
          onChange={(e) => setSecond(e.target.value)}
        >
          {components
            .filter((c) => c.name !== first)
            .map((c) => (
              <option>{c.name}</option>
            ))}
        </Select>
      </FormControl>
      <FormControl id="type" color={itemColor}>
        <FormLabel>Link Type</FormLabel>
        <Select
          value={type}
          onChange={(e) => setType(e.target.value as LinkType)}
        >
          {LinkTypeArray.map((t) => (
            <option>{t}</option>
          ))}
        </Select>
      </FormControl>
      <Button
        onClick={() => {
          if (!first.length || !second.length) {
            setError("Empty field(s)");
          }
          const source = components.find((c) => c.name === first)?.name;
          const target = components.find((c) => c.name === second)?.name;
          if (!source || !target) {
            return;
          }
          addLink({
            source,
            target,
            type,
            preview: true,
          });
          goToMenu();
        }}
      >
        Create link
      </Button>
    </VStack>
  );
};

export default CreateLink;
